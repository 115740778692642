import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { SelectChangeEvent } from "@mui/material";
import { toast } from "react-toastify";
import { unwrapResult } from "@reduxjs/toolkit";

import {
  CustomAlert,
  CustomButton,
  CustomSelect,
  Picker,
} from "../../../shared/uiComponents";
import {
  dateNormalizer,
  errorNormalizer,
} from "../../../shared/Helpers/functions";

import { DispatchProperties, useSelector } from "../../../redux/store";
import {
  getDefaultBCBA,
  getDefaultBCBAOptions,
  updateDefaultBCBA,
} from "../../../redux/State/clientSlice/userClientSlice";
import { UpdateDefaultBCBAProperties } from "../../../redux/API/ClientAPIHelpers/userClientProperties";
import dayjs, { Dayjs } from "dayjs";
import { TimeInputsWrapper } from "../../Reports/helpers";
import { useCompareDates } from "../../../shared/Helpers/hooks";

const DefaultBCBA = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const { clientId } = useParams();

  const [open, setOpen] = useState<boolean>(false);

  const onDefaultBCBAsetHandler = () => {
    if (!clientId) return;
    setOpen(true);
    dispatch(getDefaultBCBA(clientId));
    dispatch(getDefaultBCBAOptions(clientId));
  };

  return (
    <>
      <CustomButton
        title="Set Default BCBA"
        onClick={onDefaultBCBAsetHandler}
        className="marginTop8 marginBottom8"
      />
      <CustomAlert
        open={open}
        title="Update default BCBA"
        onClose={() => setOpen(false)}
        Content={({ onClose }) => <DefaultBCBAContent onClose={onClose} />}
      />
    </>
  );
};

const today = dayjs();
const startDayOfMonth = today.startOf("month");

const DefaultBCBAContent = ({ onClose }: { onClose: () => void }) => {
  const dispatch = useDispatch<DispatchProperties>();
  const { clientId } = useParams();

  const error = useSelector((state) => state.userClient.error);
  const loading = useSelector((state) => state.userClient.loading);
  const defaultBCBA = useSelector((state) => state.userClient.defaultBCBA);
  const defaultBCBAoptions = useSelector(
    (state) => state.userClient.defaultBCBAOptions
  );

  const [startDateValue, setStartDateValue] = useState<Dayjs>(startDayOfMonth);
  const [endDateValue, setEndDateValue] = useState<Dayjs>(today);
  const [userId, setUserId] = useState<string>("");

  const { hasError, message } = useCompareDates(startDateValue, endDateValue);

  const updateDefaultBCBAHandler = () => {
    if (!userId || !clientId) return;
    const startDate = dateNormalizer(startDateValue);
    const endDate = dateNormalizer(endDateValue);
    const data: UpdateDefaultBCBAProperties = {
      clientId,
      data: { userId, startDate, endDate },
    };
    dispatch(updateDefaultBCBA(data))
      .then(unwrapResult)
      .then(() => {
        toast("Success");
      })
      .catch(errorNormalizer)
      .finally(() => onClose());
  };

  const selectDefaultBCBAHandler = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    if (!value) return;
    setUserId(value);
  };

  const startDateHandler = (date: Dayjs | null) => {
    if (!date) return;
    setStartDateValue(date);
  };

  const endDateHandler = (date: Dayjs | null) => {
    if (!date) return;
    setEndDateValue(date);
  };

  useEffect(() => {
    if (!defaultBCBA) return;
    setUserId(defaultBCBA.id);
  }, [defaultBCBA]);

  return (
    <div>
      <CustomSelect
        value={userId}
        setValue={selectDefaultBCBAHandler}
        data={defaultBCBAoptions.map((x) => ({ id: x.id, label: x.fullName }))}
        error={!!error.title}
        errorMessage={error.title}
        className="marginBottom16"
      />
      <TimeInputsWrapper>
        <Picker.CustomDate
          label="Start Date"
          value={startDateValue}
          onChange={startDateHandler}
        />
        <Picker.CustomDate
          label="End Date"
          error={hasError}
          errorMessage={message}
          value={endDateValue}
          onChange={endDateHandler}
        />
      </TimeInputsWrapper>
      <CustomButton
        title="Save"
        onClick={updateDefaultBCBAHandler}
        loading={loading}
      />
    </div>
  );
};

export default DefaultBCBA;
