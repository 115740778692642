import { FetchAPI } from "../Main/fetchAPI";
import { CLIENTS } from "../constants";
import { GetReinforcementProperties, UpdateReinforcementProperties } from "./reinforcementProperties";

export const Reinforcement = {
    getReinforcements: ({ clientId }: GetReinforcementProperties) =>
        FetchAPI.getJson({
            urlStr: `${CLIENTS}/${clientId}/reinforcements`,
        }),
    updateReinforcements: ({ clientId, data }: UpdateReinforcementProperties) =>
        FetchAPI.postJson({
            urlStr: `${CLIENTS}/${clientId}/reinforcements`,
            data
        }),
}