import { useEffect, useReducer } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { errorNormalizer } from "../../../shared/Helpers/functions";
import { useResetCalendarData } from "../../Calendar/Helpers/hooks";
import { SaveDirectSupervisionProperties } from "../SoapNoteProperties/directSupervisionTypeProperties";
import {
  ACTIONS,
  directSuperVisionReducer,
  initialDirectSupervisionState,
} from "./directSupervisionReducer";
import DirectSupervisionData from "./directSupervisionData";
import {
  ForcedOperation,
  hasAnyData,
  removeSavedSoapNoteData,
  SaveSoapNote,
  SOAP_NOTES,
  SoapNoteButtons,
} from "../helpers";

import { DispatchProperties, useSelector } from "../../../redux/store";
import { saveDirectSupervision } from "../../../redux/State/clientSlice/soapNoteSlice";

const CreateSoapNote = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const { resetData } = useResetCalendarData();

  const paramsStatus = params.get("soapNoteStatus");
  const soapNoteStatus = !!paramsStatus ? parseInt(paramsStatus) : 0;

  const [data, setData] = useReducer(
    directSuperVisionReducer,
    initialDirectSupervisionState
  );

  const sessionInfo = useSelector((state) => state.session.sessionInfo);

  const createSoapNoteHandler = (submit: boolean) => {
    const sessionId = params.get("sessionId");
    const forcedOperation =
      params.get("forcedOperation") === "true" ? true : false;
    if (!sessionId || !data) return;
    const { date, startTime, endTime, user, client } = sessionInfo;
    const allData: SaveDirectSupervisionProperties = {
      ...data,
      date,
      startTime,
      endTime,
      printedName: user.fullName.toUpperCase(),
      submit,
      sessionId,
      forcedOperation,
    };
    dispatch(
      saveDirectSupervision({
        clientId: client.id,
        data: allData,
      })
    )
      .then(unwrapResult)
      .then((response) => {
        removeSavedSoapNoteData(sessionId);
        resetData();
        if (response.status) {
          toast(`Status: ${response.status.name}`);
        }
        toast("Success");
        navigate("/calendar");
      })
      .catch(errorNormalizer);
  };

  useEffect(() => {
    const sessionId = params.get("sessionId");
    const notes = window.localStorage.getItem(SOAP_NOTES);
    const soapNotes: Array<any> = notes ? JSON.parse(notes) : [];
    const savedData = soapNotes.find((x) => x.sessionId === sessionId);

    if (!sessionId) return;
    if (!hasAnyData(data) && !!savedData) {
      setData({
        type: ACTIONS.setState,
        payload: savedData,
      });
      return;
    }

    const { date, startTime, endTime, user, client } = sessionInfo;
    const allData = {
      ...data,
      sessionId,
      date,
      startTime,
      endTime,
      printedName: user.fullName.toUpperCase(),
      submit: false,
      client,
    };
    SaveSoapNote(allData);
  }, [data, params, sessionInfo]);

  return (
    <>
      <DirectSupervisionData data={data} setData={setData} />
      <SoapNoteButtons
        status={soapNoteStatus}
        save={() => createSoapNoteHandler(false)}
        submit={() => createSoapNoteHandler(true)}
      />
      <ForcedOperation submit={() => createSoapNoteHandler(true)} />
    </>
  );
};
export default CreateSoapNote;
