import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { Chip, InputBase, InputProps, styled } from "@mui/material";
import { Add, EditOutlined } from "@mui/icons-material";

import {
  black500,
  black900,
  white100,
} from "../../../../shared/Helpers/colors";
import { CustomAlert, CustomButton } from "../../../../shared/uiComponents";
import { errorNormalizer } from "../../../../shared/Helpers/functions";

import { DispatchProperties, useSelector } from "../../../../redux/store";
import { ReinforcementProperties } from "../../../../redux/API/ClientAPIHelpers/reinforcementProperties";
import { updateReinforcements } from "../../../../redux/State/clientSlice/programTargetSlice";

const Input = styled(InputBase)<InputProps>((props) => ({
  border: "0px solid",
  borderRadius: "10px",
  background: "none",
  color: black900,
  padding: 0,
  width: "100%",
  height: "40px",
  // borderBottom: props.error ? "2px solid" : "1px solid",
  // borderBottomColor: props.error ? red500 : black500,
  "& .MuiInputBase-input": {
    padding: "8px 10px",
    "&:focus": {
      borderRadius: "10px",
      boxShadow: `none`,
    },
  },
}));

const CustomizedInput = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  border: "1px solid",
  borderColor: black500,
  borderRadius: "10px",
  background: white100,
  color: black900,
  padding: 0,
  width: "100%",
}));

const EditReinforcements = () => {
  const [open, setOpen] = useState<boolean>(false);

  const openHandler = () => setOpen(true);
  const closeHandler = () => setOpen(false);

  return (
    <div>
      <EditOutlined fontSize="small" onClick={openHandler} />
      <CustomAlert
        open={open}
        onClose={closeHandler}
        title="Reinforcements Items"
        Content={({ onClose }) => <Content onClose={onClose} />}
      />
    </div>
  );
};

const Content = ({ onClose }: { onClose: () => void }) => {
  const dispatch = useDispatch<DispatchProperties>();
  const { clientId } = useParams();

  const reinforcements = useSelector(
    (state) => state.programTarget.reinforcementsList
  );

  const [list, setList] = useState<Array<ReinforcementProperties>>([]);
  const [value, setValue] = useState<string>("");

  const addReinforcementHandler = () => {
    if(!value) return;
    setValue("");
    if (list.find((x) => x.title === value)) return;
    setList((prev) => [...prev, { title: value }]);
  };

  const removeReinforcementHandler = (id: number) => {
    setList((prev) => prev.filter((_, index) => index !== id));
  };

  const valueHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setValue(value);
  };

  const saveReinforcementListHandler = () => {
    if (!clientId) return;
    dispatch(updateReinforcements({ clientId, data: list }))
      .then(unwrapResult)
      .then(() => onClose())
      .catch(errorNormalizer);
  };

  useEffect(() => {
    if (!reinforcements?.length) return;
    setList(reinforcements);
  }, [reinforcements]);

  return (
    <>
      <CustomizedInput>
        <div
          style={{
            display: "flex",
            gap: "8px",
            flexWrap: "wrap",
            padding: "8px",
          }}
        >
          {list.map((x, index) => (
            <Chip
              key={index}
              label={x.title}
              onDelete={() => removeReinforcementHandler(index)}
            />
          ))}
          <Input value={value} onChange={valueHandler} placeholder="Add..." />
        </div>
        <Add
          fontSize="small"
          style={{ marginRight: "8px" }}
          onClick={addReinforcementHandler}
        />
      </CustomizedInput>
      <CustomButton
        title="Save"
        className="marginTop16"
        onClick={saveReinforcementListHandler}
      />
    </>
  );
};

export default EditReinforcements;
