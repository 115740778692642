import { useCallback, useEffect, useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import {
  CustomAlert,
  CustomButton,
  CustomTable,
  Text,
} from "../../../shared/uiComponents";
import { errorNormalizer } from "../../../shared/Helpers/functions";
import { ButtonWrapper } from "../../../components/StyledComponents";
import SearchBar from "../../../components/SearchBar";
import { SignatureInput } from "../../SOAPNote/helpers";
import { Headers, TableBody } from "./tableInfo";
import { ReportFilters } from "../../Reports/helpers";

import { DispatchProperties, useSelector } from "../../../redux/store";
import {
  approveSoapNotes,
  getPendingSoapNotes,
} from "../../../redux/State/clientSlice/soapNoteSlice";
import {
  ApprovePendingSoapNotesProperties,
  GetPendingSoapNotesProperties,
} from "../../../redux/API/ClientAPIHelpers/soapNoteProperties";

const PendingApproval = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params] = useSearchParams();

  const [open, setOpen] = useState<boolean>(false);

  const notes = useSelector((state) => state.soapNote.pendingSoapNotes);
  const loading = useSelector((state) => state.soapNote.loading);
  const filterData = useSelector((state) => state.billing.billingFilter);

  const openApproveHandler = () => setOpen(true);
  const closeApproveHandler = () => setOpen(false);

  useEffect(() => {
    const page = params.get("page") || "1";
    const pageSize = params.get("pageSize") || "8";
    const direction = params.get("direction") || "";
    const orderBy = params.get("orderBy") || "";
    const searchString = params.get("searchString") || "";
    const { clientId, endDate, startDate, userId } = filterData;

    if (!startDate || !endDate) return;
    const data: GetPendingSoapNotesProperties = {
      page,
      pageSize,
      startDate,
      endDate,
      direction,
      orderBy,
      searchString,
      userId,
      clientId,
    };

    dispatch(getPendingSoapNotes(data))
      .then(unwrapResult)
      .catch(errorNormalizer);
  }, [dispatch, params, filterData]);

  return (
    <div style={{ width: "720px" }}>
      <div style={{ width: "350px" }} className="marginBottom16">
        <CustomButton onClick={openApproveHandler} title={"Approve all"} />
        <CustomAlert
          open={open}
          onClose={closeApproveHandler}
          title="Are you sure?"
          Content={({ onClose }) => <Content onClose={onClose} />}
        />
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Text title={"Pending SOAP Notes"} size="mediumBold" />
        <SearchBar />
      </div>
      <ReportFilters showUsers={false} showShorcuts={false} />
      <CustomTable
        headers={Headers}
        TableBody={TableBody}
        data={notes}
        loading={loading}
        hasMobile={false}
      />
    </div>
  );
};

const Content = ({ onClose }: { onClose: () => void }) => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params] = useSearchParams();

  const [signature, setSignature] = useState<string>("");

  const filterData = useSelector((state) => state.billing.billingFilter);
  const notes = useSelector((state) => state.soapNote.pendingSoapNotes);
  const loading = useSelector((state) => state.soapNote.loading);

  const approvePageHandler = useCallback(() => {
    const page = params.get("page") || "1";
    const pageSize = params.get("pageSize") || "1";
    const direction = params.get("direction") || "";
    const orderBy = params.get("orderBy") || "";
    const searchString = params.get("searchString") || "";
    const { clientId, endDate, startDate, userId } = filterData;

    if (!startDate || !endDate) return;

    const data: ApprovePendingSoapNotesProperties = {
      page,
      pageSize,
      startDate,
      endDate,
      direction,
      orderBy,
      searchString,
      userId,
      clientId,
      signature,
    };
    dispatch(approveSoapNotes(data))
      .then(unwrapResult)
      .then(() => {
        toast("Success");
        dispatch(getPendingSoapNotes(data));
        onClose();
      })
      .catch(errorNormalizer);
  }, [dispatch, onClose, params, filterData, signature]);

  const approveAllHandler = useCallback(() => {
    const page = params.get("page") || "1";
    const direction = params.get("direction") || "";
    const orderBy = params.get("orderBy") || "";
    const searchString = params.get("searchString") || "";
    const pageSize = !!notes.totalNumberOfItems
      ? notes.totalNumberOfItems.toString()
      : "8";
    const { clientId, endDate, startDate, userId } = filterData;

    if (!startDate || !endDate) return;

    const data: ApprovePendingSoapNotesProperties = {
      page,
      pageSize,
      startDate,
      endDate,
      direction,
      orderBy,
      searchString,
      userId,
      clientId,
      signature,
    };
    dispatch(approveSoapNotes(data))
      .then(unwrapResult)
      .then(() => {
        toast("Success");
        dispatch(getPendingSoapNotes(data));
        onClose();
      })
      .catch(errorNormalizer);
  }, [dispatch, onClose, params, notes, filterData, signature]);

  return (
    <>
      <SignatureInput
        value={signature}
        setValue={({ target }) => setSignature(target.value.toUpperCase())}
        disabled={false}
        onBlur={() => {}}
        error={false}
      />
      <ButtonWrapper className="marginTop32">
        <CustomButton
          onClick={approvePageHandler}
          title={"Approve page"}
          loading={loading}
          disabled={!signature || notes.totalNumberOfItems === 0}
        />
        <CustomButton
          onClick={approveAllHandler}
          title={"Approve all"}
          loading={loading}
          disabled={!signature || notes.totalNumberOfItems === 0}
        />
      </ButtonWrapper>
    </>
  );
};

export default PendingApproval;
