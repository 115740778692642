import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  TableBody as Body,
  CircularProgress,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import dayjs from "dayjs";
import {
  CheckCircleOutlineOutlined,
  RemoveRedEyeOutlined as View,
} from "@mui/icons-material";

import { HeaderProperties } from "../../../shared/uiComponents/Table/tableProperties";
import { CustomAlert, CustomButton } from "../../../shared/uiComponents";
import { SignatureInput } from "../../SOAPNote/helpers";

import { DispatchProperties, useSelector } from "../../../redux/store";
import {
  approveSoapNote,
  getPendingSoapNotes,
} from "../../../redux/State/clientSlice/soapNoteSlice";
import {
  ApprovePendingSoapNotesProperties,
  SoapNotesApprovalProperties,
} from "../../../redux/API/ClientAPIHelpers/soapNoteProperties";
import { SoapNotesStatuses } from "../../../redux/API/ClientAPIHelpers/dataCollectionProperties";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getSoapNotePDF } from "../../../redux/State/exportSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { errorNormalizer } from "../../../shared/Helpers/functions";
import { toast } from "react-toastify";

export const Headers: Array<HeaderProperties> = [
  { id: "1", name: "Provider", orderBy: "client.fullName" },
  { id: "2", name: "Client", orderBy: "client.fullName" },
  { id: "3", name: "Session Date", orderBy: "sessionDate" },
  { id: "4", name: "View" },
  { id: "4", name: "Approve" },
];

const ApproveNote = ({ reportId }: { reportId: string }) => {
  const [open, setOpen] = useState<boolean>(false);

  const openApproveHandler = () => setOpen(true);
  const closeApproveHandler = () => setOpen(false);

  return (
    <>
      <Tooltip title="Approve">
        <CheckCircleOutlineOutlined
          fontSize="small"
          onClick={openApproveHandler}
        />
      </Tooltip>
      <CustomAlert
        open={open}
        onClose={closeApproveHandler}
        title="Are you sure?"
        Content={({ onClose }) => (
          <Content reportId={reportId} onClose={onClose} />
        )}
      />
    </>
  );
};

const Content = ({
  reportId,
  onClose,
}: {
  reportId: string;
  onClose: () => void;
}) => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params] = useSearchParams();

  const [signature, setSignature] = useState<string>("");
  const filterData = useSelector((state) => state.billing.billingFilter);
  const loading = useSelector((state) => state.soapNote.loading);

  const approveHandler = () => {
    if (!signature) return;
    dispatch(approveSoapNote({ reportId, signature }))
      .then(unwrapResult)
      .then(() => {
        toast("Success");
        const page = params.get("page") || "1";
        const direction = params.get("direction") || "";
        const orderBy = params.get("orderBy") || "";
        const searchString = params.get("searchString") || "";
        const pageSize = params.get("page") || "8";
        const { clientId, endDate, startDate, userId } = filterData;

        const data: ApprovePendingSoapNotesProperties = {
          page,
          pageSize,
          startDate,
          endDate,
          direction,
          orderBy,
          searchString,
          userId,
          clientId,
          signature,
        };
        dispatch(getPendingSoapNotes(data));
        onClose();
      });
  };

  return (
    <>
      <SignatureInput
        value={signature}
        setValue={({ target }) => setSignature(target.value.toUpperCase())}
        disabled={false}
        onBlur={() => {}}
        error={false}
      />
      <CustomButton
        onClick={approveHandler}
        title={"Approve"}
        className="marginTop32"
        loading={loading}
        disabled={!signature}
      />
    </>
  );
};

export const TableBody = ({
  data,
}: {
  data: Array<SoapNotesApprovalProperties>;
}) => {
  const dispatch = useDispatch<DispatchProperties>();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.export.loading);

  const viewSoapNoteHandler = (item: SoapNotesApprovalProperties) => {
    const { status, sessionType, client, id, sessionId } = item;
    if (
      SoapNotesStatuses.expiredWeek === status.status ||
      SoapNotesStatuses.expired48 === status.status ||
      SoapNotesStatuses.unlocked === status.status ||
      SoapNotesStatuses.draft === status.status
    ) {
      const query = new URLSearchParams();
      query.set("type", `${sessionType.type}`);
      query.set("soapNoteStatus", `${status.status}`);
      query.set("clientName", client.fullName);
      query.set("reportId", id);
      query.set("sessionId", sessionId);
      query.set("clientId", client.id);
      navigate(`/soap-notes/edit?${query.toString()}`);
      return;
    }
    dispatch(
      getSoapNotePDF({
        reportId: id,
        includeTrials: true,
      })
    )
      .then(unwrapResult)
      .then((response) => {
        const objectURL = URL.createObjectURL(response);
        window.open(objectURL);
      })
      .catch(errorNormalizer);
  };

  return (
    <Body>
      {data.map((row, index) => (
        <TableRow key={index}>
          <TableCell>{row.user.fullName}</TableCell>
          <TableCell>{row.client.fullName}</TableCell>
          <TableCell>{dayjs.utc(row.date).format("MM-DD-YYYY")}</TableCell>
          <TableCell>
            {!loading ? (
              <Tooltip title="View">
                <View
                  fontSize="small"
                  onClick={() => viewSoapNoteHandler(row)}
                  style={{ marginRight: "8px" }}
                />
              </Tooltip>
            ) : (
              <div style={{ width: "24px", height: "24px" }}>
                <CircularProgress size={16} />
              </div>
            )}
          </TableCell>
          <TableCell>
            <ApproveNote reportId={row.id} />
          </TableCell>
        </TableRow>
      ))}
    </Body>
  );
};
