import { useEffect, ChangeEvent, useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";

import {
  CustomAlert,
  CustomButton,
  CustomCheckBox,
  CustomTable,
  Picker,
  Text,
} from "../../../shared/uiComponents";
import SearchBar from "../../../components/SearchBar";
import { Headers, TableBody } from "./tableInfo";

import { DispatchProperties, useSelector } from "../../../redux/store";
import {
  getAvailableNotCredentialedBCBAs,
  getBillableUsers,
  getClientBillableUser,
  setBillingUserId,
} from "../../../redux/State/clientSlice/userClientSlice";
import dayjs, { Dayjs } from "dayjs";
import { useCompareDates } from "../../../shared/Helpers/hooks";
import { TimeInputsWrapper } from "../../Reports/helpers";
import { ButtonWrapper } from "../../../components/StyledComponents";
import { dateNormalizer } from "../../../shared/Helpers/functions";

const today = dayjs();
const startDayOfMonth = today.startOf("month");

const NotCredentialedBCBA = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params, setParams] = useSearchParams();
  const { clientId } = useParams();

  const [open, setOpen] = useState<boolean>(false);
  const [startDateValue, setStartDateValue] = useState<Dayjs>(startDayOfMonth);
  const [endDateValue, setEndDateValue] = useState<Dayjs>(today);

  const { hasError, message } = useCompareDates(startDateValue, endDateValue);

  const bcbas = useSelector((state) => state.userClient.notCredentialedBCBAs);
  const billableUsers = useSelector((state) => state.userClient.billableUsers);
  const loading = useSelector(
    (state) => state.userClient.loadingGetAvailableBCBAs
  );

  const startDateHandler = (date: Dayjs | null) => {
    if (!date) return;
    setStartDateValue(date);
  };

  const endDateHandler = (date: Dayjs | null) => {
    if (!date) return;
    setEndDateValue(date);
  };

  useEffect(() => {
    dispatch(getBillableUsers());
  }, [dispatch]);

  useEffect(() => {
    if (!clientId || !!params.get("nc-billing-user")) return;
    dispatch(getClientBillableUser(clientId))
      .then(unwrapResult)
      .then((response) => {
        params.set("nc-billing-user", response.id);
        setParams(params);
      });
  }, [dispatch, clientId, params, setParams]);

  useEffect(() => {
    if (!clientId) return;
    const page = params.get("page") || "1";
    const pageSize = params.get("pageSize") || "8";
    const orderBy = params.get("orderBy") || "";
    const direction = params.get("direction") || "";
    const searchString = params.get("search") || "";
    const status = params.get("status") || "";

    dispatch(
      getAvailableNotCredentialedBCBAs({
        clientId,
        page,
        pageSize,
        orderBy,
        direction,
        searchString,
        status,
      })
    );
  }, [params, clientId, dispatch]);

  const onBillableUserChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    if (!name) return;
    dispatch(setBillingUserId(name));
    params.set("nc-billing-user", name);
    setParams(params);
  };

  const setDateRangeHandler = () => {
    const startDate = dateNormalizer(startDateValue);
    const endDate = dateNormalizer(endDateValue);
    params.set("startDate", startDate);
    params.set("endDate", endDate);
    setParams(params);
    setOpen(false);
  };

  return (
    <div className="marginTop16">
      <div
        className="marginLeft8 billableWrapper"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div className="billableUserWrapper" onClick={() => setOpen(true)}>
          <Text title={"Choose billable BCBA: "} size="tinyBold" />
          <div>
            {billableUsers.map((user) => (
              <CustomCheckBox
                key={user.id}
                item={{
                  id: user.id,
                  label: user.fullName,
                  checked: user.id === params.get("nc-billing-user"),
                }}
                onChange={onBillableUserChange}
              />
            ))}
          </div>
        </div>
        <CustomAlert
          open={open}
          onClose={() => setOpen(false)}
          Content={() => (
            <>
              <TimeInputsWrapper>
                <Picker.CustomDate
                  label="Start Date"
                  value={startDateValue}
                  onChange={startDateHandler}
                />
                <Picker.CustomDate
                  label="End Date"
                  error={hasError}
                  errorMessage={message}
                  value={endDateValue}
                  onChange={endDateHandler}
                />
              </TimeInputsWrapper>
              <ButtonWrapper>
                <CustomButton
                  title="Set"
                  onClick={setDateRangeHandler}
                  loading={loading}
                />
                <CustomButton
                  title="Close"
                  secondaryButton
                  onClick={() => setOpen(false)}
                  loading={loading}
                />
              </ButtonWrapper>
            </>
          )}
        />
        <SearchBar label="Find" />
      </div>
      <CustomTable
        headers={Headers}
        data={bcbas}
        TableBody={TableBody}
        loading={loading}
        hasMobile={false}
      />
    </div>
  );
};

export default NotCredentialedBCBA;
