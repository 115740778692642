import { useState } from "react";
import { RemoveRedEyeOutlined } from "@mui/icons-material";

import { CustomAlert, Text } from "../../../../shared/uiComponents";

import { useSelector } from "../../../../redux/store";

const ViewReinforcements = () => {
  const [open, setOpen] = useState<boolean>(false);

  const reinforcements = useSelector(
    (state) => state.programTarget.reinforcementsList
  );

  const openHandler = () => setOpen(true);
  const closeHandler = () => setOpen(false);

  return (
    <>
      <RemoveRedEyeOutlined onClick={openHandler} fontSize="small" />
      <CustomAlert
        open={open}
        onClose={closeHandler}
        title="Reinforcements Items"
        Content={() => (
          <div>
            {reinforcements.map((x, index) => (
              <Text key={index} title={`${index + 1}. ${x.title}`} />
            ))}
          </div>
        )}
      />
    </>
  );
};

export default ViewReinforcements;
