import { Client } from "./ClientAPIHelpers/client";
import { ClientProgram } from "./ClientAPIHelpers/clientProgram";
import { SkillArea } from "./ClientAPIHelpers/skillArea";
import { UserClient } from "./ClientAPIHelpers/userClient";
import { PromptCode } from "./ClientAPIHelpers/promptCode";
import { ProgramTargets } from "./ClientAPIHelpers/programTargets";
import { DataCollection } from "./ClientAPIHelpers/dataCollection";
import { BehaviorReduction } from "./ClientAPIHelpers/behaviorReduction";
import { Sessions } from "./ClientAPIHelpers/sessions";
import { Calendar } from "./ClientAPIHelpers/calendar";
import { SoapNote } from "./ClientAPIHelpers/soapNote";
import { Billing } from "./ClientAPIHelpers/billing";
import { Insurances } from "./ClientAPIHelpers/insurances";
import { InsuranceAuthorization } from "./ClientAPIHelpers/insuranceAuthorization";
import { Graph } from "./ClientAPIHelpers/graph";
import { GraphAnnotation } from "./ClientAPIHelpers/graphAnnotation";
import { RoleConfiguration } from "./ClientAPIHelpers/roleConfiguration";
import { DiagnosisCode } from "./ClientAPIHelpers/diagnosisCode";
import { MasteryCriteria } from "./ClientAPIHelpers/masteryCriteris";
import { ExcelReport } from "./ClientAPIHelpers/excelReport";
import { TelehealthLocation } from "./ClientAPIHelpers/telehealthLocation";
import { Reinforcement } from "./ClientAPIHelpers/reinforcement";

export const ClientAPI = {
  BehaviorReduction,
  Billing,
  Calendar,
  Client,
  ClientProgram,
  DataCollection,
  Graph,
  GraphAnnotation,
  Insurances,
  InsuranceAuthorization,
  ProgramTargets,
  PromptCode,
  RoleConfiguration,
  Sessions,
  SkillArea,
  SoapNote,
  UserClient,
  DiagnosisCode,
  MasteryCriteria,
  ExcelReport,
  TelehealthLocation,
  Reinforcement,
};
