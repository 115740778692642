import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  TableBody as Body,
  CircularProgress,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import dayjs from "dayjs";
import { RemoveRedEyeOutlined as View } from "@mui/icons-material";

import { HeaderProperties } from "../../../shared/uiComponents/Table/tableProperties";
import { errorNormalizer } from "../../../shared/Helpers/functions";

import { DispatchProperties, useSelector } from "../../../redux/store";
import {
  SoapNotesFullProperties,
  StatusProperties,
} from "../../../redux/API/ClientAPIHelpers/soapNoteProperties";
import { SoapNotesStatuses } from "../../../redux/API/ClientAPIHelpers/dataCollectionProperties";
import { getSoapNotePDF } from "../../../redux/State/exportSlice";

export const Headers: Array<HeaderProperties> = [
  { id: "1", name: "Provider", orderBy: "client.fullName" },
  { id: "2", name: "Client", orderBy: "client.fullName" },
  { id: "3", name: "Session Date", orderBy: "sessionDate", width: "200px" },
  { id: "4", name: "Status", width: "200px" },
  {
    id: "5",
    name: "Supervised Session",
    width: "120px",
  },
  { id: "6", name: "Action", width: "100px" },
];

interface DSUSoapNoteProperties extends SoapNotesFullProperties {
  supervisedSessionStatus: StatusProperties;
}

export const TableBody = ({ data }: { data: Array<DSUSoapNoteProperties> }) => {
  const dispatch = useDispatch<DispatchProperties>();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.export.loading);

  const viewSoapNoteHandler = (item: DSUSoapNoteProperties) => {
    const { status, sessionType, client, id, sessionId } = item;
    if (
      SoapNotesStatuses.expiredWeek === status.status ||
      SoapNotesStatuses.expired48 === status.status ||
      SoapNotesStatuses.unlocked === status.status ||
      SoapNotesStatuses.draft === status.status
    ) {
      const query = new URLSearchParams();
      query.set("type", `${sessionType.type}`);
      query.set("soapNoteStatus", `${status.status}`);
      query.set("clientName", client.fullName);
      query.set("reportId", id);
      query.set("sessionId", sessionId);
      query.set("clientId", client.id);
      navigate(`/soap-notes/edit?${query.toString()}`);
      return;
    }
    dispatch(
      getSoapNotePDF({
        reportId: id,
        includeTrials: true,
      })
    )
      .then(unwrapResult)
      .then((response) => {
        const objectURL = URL.createObjectURL(response);
        window.open(objectURL);
      })
      .catch(errorNormalizer);
  };

  return (
    <Body>
      {data.map((row, index) => (
        <TableRow key={index}>
          <TableCell>{row.user.fullName}</TableCell>
          <TableCell>{row.client.fullName}</TableCell>
          <TableCell>{dayjs.utc(row.date).format("MM-DD-YYYY")}</TableCell>
          <TableCell>{row.status.name}</TableCell>
          {
            <TableCell>
              {!!row.supervisedSessionStatus
                ? row.supervisedSessionStatus.name
                : "-"}
            </TableCell>
          }
          <TableCell>
            {!loading ? (
              <Tooltip title="View">
                <View
                  fontSize="small"
                  onClick={() => viewSoapNoteHandler(row)}
                  style={{ marginRight: "8px" }}
                />
              </Tooltip>
            ) : (
              <div style={{ width: "24px", height: "24px" }}>
                <CircularProgress size={16} />
              </div>
            )}
          </TableCell>
        </TableRow>
      ))}
    </Body>
  );
};
