import { AdminTypes } from "../../../../components/Menu";
import { Text } from "../../../../shared/uiComponents";
import EditReinforcements from "./editReinforcements";
import ViewReinforcements from "./viewReinforcements";

import { useSelector } from "../../../../redux/store";

const ReinforcementItems = () => {
  const role = useSelector((state) => state.account.role);
  const reinforcements = useSelector(
    (state) => state.programTarget.reinforcementsList
  );

  const reinforcementList =
    !!reinforcements?.length && reinforcements.map((x) => x.title).join(", ");

  return (
    <div style={{ display: "flex" }}>
      {!!reinforcementList && (
        <>
          <Text
            title={
              reinforcementList.length > 50
                ? `${reinforcementList.slice(0, 40)}...`
                : reinforcementList
            }
          />
          <ViewReinforcements />
        </>
      )}

      {role?.section.id !== AdminTypes.bt && <EditReinforcements />}
    </div>
  );
};

export default ReinforcementItems;
