import { Text } from "../../shared/uiComponents";
import { Header } from "../../components/StyledComponents";
import SearchBar from "../../components/SearchBar";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "../../redux/store";
import { AdminTypes } from "../../components/Menu";

const SoapNotesHeader = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const role = useSelector((state) => state.account.role);

  const navigateToSoapNotes = () => navigate("/soap-notes");
  const navigateToPendingApproval = () => navigate("/soap-notes/dsu");

  return (
    <Header className="padding8">
      <div style={{ display: "flex", gap: "16px", flexWrap: "wrap" }}>
        <Text
          title={"SOAP Notes"}
          size="mediumBold"
          onClick={navigateToSoapNotes}
          className={`padding8 ${!pathname.includes("dsu") ? "title" : ""}`}
        />
        {role?.section.id === AdminTypes.admin && (
          <Text
            title={"DSU SOAP Notes"}
            size="mediumBold"
            onClick={navigateToPendingApproval}
            className={`padding8 ${!!pathname.includes("dsu") ? "title" : ""}`}
          />
        )}
      </div>
      <Header>
        <SearchBar />
      </Header>
    </Header>
  );
};

export default SoapNotesHeader;
