import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { styled } from "@mui/material";
import dayjs from "dayjs";

import {
  CustomButton,
  ClientStatusBadge,
  Text,
} from "../../../shared/uiComponents";
import { AdminTypes } from "../../../components/Menu";
import { Row } from "../../../components/StyledComponents";

import { DispatchProperties, useSelector } from "../../../redux/store";
import { clearActiveAuthorization } from "../../../redux/State/clientSlice/insuranceAuthorizationSlice";
import {
  clearClients,
  clearTherapists,
} from "../../../redux/State/clientSlice/clientSlice";
import { getReinforcements } from "../../../redux/State/clientSlice/programTargetSlice";
import ReinforcementItems from "./ReinforcementItems";

const Wrapper = styled("div")(() => ({
  maxWidth: "360px",
  paddingRight: "16px",
  paddingBottom: "16px",
}));

const ClientInfo = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const { clientId } = useParams();

  const {
    id,
    fullName,
    dateOfBirthday,
    address,
    insurance,
    insuranceNumber,
    status,
    caseCoordinator,
  } = useSelector((state) => state.client.client);
  const role = useSelector((state) => state.account.role);
  const reinforcements = useSelector(
    (state) => state.programTarget.reinforcementsList
  );

  const navigate = useNavigate();

  const navigateAssignTherapistHandler = () => {
    if (!id) return;
    navigate(`/clients/client/assign/${id}`);
    dispatch(clearActiveAuthorization());
    dispatch(clearTherapists());
    dispatch(clearClients());
  };

  const navigateAllocateHours = () => {
    dispatch(clearActiveAuthorization());
    navigate(`/clients/client/allocate-authorization/${id}`);
  };

  useEffect(() => {
    if (!clientId) return;
    dispatch(getReinforcements({ clientId }));
  }, [clientId, dispatch]);

  return (
    <Wrapper>
      <Text title={fullName} size="largeBold" />
      <Row>
        <Text title={`DOB:`} size="smallBold" />
        <Text title={dayjs(dateOfBirthday).format("MM-DD-YYYY")} size="small" />
      </Row>
      <Row>
        <Text title={`Address:`} size="smallBold" />
        <Text title={address} size="small" />
      </Row>

      {insurance && (
        <Row>
          <Text title={`Insurance:`} size="smallBold" />
          <Text title={insurance.name} size="small" />
        </Row>
      )}
      {insuranceNumber && (
        <Row>
          <Text title={`Member ID:`} size="smallBold" />
          <Text title={insuranceNumber} size="small" />
        </Row>
      )}
      {caseCoordinator && (
        <Row>
          <Text title={"Case Coordinator: "} className="marginBottom8" />
          <Text title={caseCoordinator.fullName} className="marginBottom8" />
        </Row>
      )}
      <div
        style={
          !reinforcements.length
            ? { display: "flex", justifyContent: "space-between" }
            : {}
        }
      >
        <Text
          title={`Reinforcement Items: `}
          size="smallBold"
          className="marginBottom8"
        />
        <ReinforcementItems />
      </div>

      <div className="marginLeft16 marginBottom16">
        <ClientStatusBadge statusCode={status.id} />
      </div>
      {role?.section.id === AdminTypes.admin && (
        <>
          <CustomButton
            title="Assign Provider"
            onClick={navigateAssignTherapistHandler}
            className="marginTop16"
            disabled={!id}
          />
          <CustomButton
            title="Allocate hours"
            secondaryButton
            onClick={navigateAllocateHours}
            className="marginTop16"
            disabled={!id}
          />
        </>
      )}
    </Wrapper>
  );
};

export default ClientInfo;
